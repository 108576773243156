import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createBook, deleteOneBook, 
// createExpense,
// deleteOneExpense,
getAllBooks, getOneBook, updateBook,
// getExpenses,
// getOneExpense,
// updateExpense,
 } from './actions';
var initialState = {
    books: { docs: [], meta: {} },
    book: null,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'books',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // CREATE ONE
        builder
            .addCase(createBook.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createBook.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.books = action.payload.data;
        })
            .addCase(createBook.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // UPDATE ONE
        builder
            .addCase(updateBook.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateBook.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.book = action.payload.data;
        })
            .addCase(updateBook.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ALL
        builder
            .addCase(getAllBooks.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllBooks.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.books = action.payload;
        })
            .addCase(getAllBooks.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getOneBook.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneBook.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.book = action.payload.data;
        })
            .addCase(getOneBook.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // DELETE ONE
        builder
            .addCase(deleteOneBook.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteOneBook.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.books.docs = state.books.docs.filter(function (book) { return book._id !== action.meta.arg; });
        })
            .addCase(deleteOneBook.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
